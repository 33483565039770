import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,6,2,3,4,5];

export const dictionary = {
		"/": [9],
		"/about": [~11],
		"/account": [12],
		"/account/update-password": [~13],
		"/auth/verify": [~14],
		"/contact": [~15],
		"/dashboard-v2": [30,[6]],
		"/dashboard-v2/accounts": [~31,[6]],
		"/dashboard-v2/application": [32,[6]],
		"/dashboard-v2/fees": [~33,[6]],
		"/dashboard-v2/history": [~34,[6]],
		"/dashboard-v2/invoices": [35,[6]],
		"/dashboard-v2/settings": [~36,[6,7]],
		"/dashboard-v2/settings/knowledge-center": [~37,[6,7,8]],
		"/dashboard-v2/settings/knowledge-center/[slug]": [38,[6,7,8]],
		"/dashboard-v2/statements": [39,[6]],
		"/dashboard-v2/transactions": [40,[6]],
		"/dashboard": [~16,[2]],
		"/dashboard/account": [~17,[2,3]],
		"/dashboard/account/[account_id]": [~18,[2,3,4]],
		"/dashboard/account/[account_id]/withdraw": [~19,[2,3,4]],
		"/dashboard/application/new": [~20,[2,5]],
		"/dashboard/application/review/[application_id]": [~21,[2,5]],
		"/dashboard/application/review/[application_id]/individual/[corp_individual_id]": [~22,[2,5]],
		"/dashboard/fees": [~23,[2]],
		"/dashboard/invoices": [~24,[2]],
		"/dashboard/invoices/new": [~26,[2]],
		"/dashboard/invoices/[id]": [~25,[2]],
		"/dashboard/knowledge-center": [~27,[2]],
		"/dashboard/knowledge-center/[slug]": [28,[2]],
		"/dashboard/referrals": [~29,[2]],
		"/e-sign-disclosure-and-consent": [~41],
		"/invoice/[id]": [~42],
		"/privacy-policy": [~43],
		"/prohibited-businesses": [~44],
		"/redirect/recovery": [45],
		"/ref/[ref_id]": [~46],
		"/request-access": [~47],
		"/restricted-jurisdictions": [~48],
		"/sign-in": [~49],
		"/sign-up": [~50],
		"/terms-and-conditions": [~51],
		"/[affiliate]": [~10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';